const CLIENT_ID = 'm1lfOKr1wwfk2OtXsqikGRYe8pV6iCPG';

const AUTH0_DOMAIN = 'cimpress.auth0.com';

const { createAuth0Client } = window.auth0;
const redirectRoute = '';
const auth0Config = {
    domain: AUTH0_DOMAIN,
    clientId: CLIENT_ID,
    authorizationParams: {
        redirect_uri: window.location.origin + redirectRoute,
        scope: 'openid profile email user_id',
        audience: 'https://api.cimpress.io/',
    },
    useRefreshTokens: false,
}

const ensureAuthentication = async () => {
    const auth0 = await createAuth0Client(auth0Config);
    if (location.search.includes("state=") &&
        (location.search.includes("code=") ||
            location.search.includes("error="))) {
        await auth0.handleRedirectCallback();
        window.history.replaceState({}, document.title, "/");
    }
    const isAuthenticated = await auth0.isAuthenticated();
    if (!isAuthenticated) {
        await auth0.loginWithRedirect();
        return;
    } else {
        const accessToken = await auth0.getTokenSilently();
        window.location.href = 'app.html?token=' + accessToken;
    }
}

$(document).ready(function () {
    ensureAuthentication();
});